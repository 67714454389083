import PropTypes from "prop-types";
import {useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {formatMoney} from "@shopify/theme-currency";
import PaginatedList from "../Pagination";
import useIsMobile from "@/hooks/useIsMobile";

const MAX_PRODUCTS_MOBILE = 3;
const TYPING_TIMEOUT = 1000;

const Search = ({suggestions, isInPageSearch = false}) => {
  const {t} = useTranslation();
  const queryParams = new URLSearchParams(document.location.search);
  const qQueryString = queryParams.get("q");

  const isMobile = useIsMobile();

  const [query, setQuery] = useState(qQueryString || "");
  const [inputValue, setInputValue] = useState(qQueryString || "");
  const [isSearching, setIsSearching] = useState(false);
  const [products, setProducts] = useState(null);
  const [articles, setArticles] = useState(null);
  const [maxProductsToShow, setMaxProductsToShow] = useState(
    isMobile ? MAX_PRODUCTS_MOBILE : 999,
  );
  const typingTimeoutRef = useRef(null);
  const totalResultCount =
    products === null ? null : articles.length + products.length;

  useEffect(() => {
    getSearchResults(query, setProducts, setArticles, setIsSearching);
    updateQueryParameter("q", query);
  }, [query]);

  const handleInputChange = useCallback(value => {
    setIsSearching(true);
    setInputValue(value);

    // Clear previous timeout if any
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    // Set a new timeout to delay the setQuery call
    typingTimeoutRef.current = setTimeout(() => {
      setQuery(value); // Update query after x miliseconds
    }, TYPING_TIMEOUT);
  }, []);

  const handleSuggestionClick = suggestion => {
    setQuery(suggestion);
    setInputValue(suggestion);
  };

  if (isInPageSearch) {
    return (
      <div className="container max-h-[calc(100vh-110px)] overflow-auto pt-[2px] text-deep-green">
        <SearchBar
          isSearching={isSearching}
          inputValue={inputValue}
          handleInputChange={handleInputChange}
          totalResultCount={totalResultCount}
          handleSuggestionClick={handleSuggestionClick}
          suggestions={suggestions}
          query={query}
          isInPageSearch={isInPageSearch}
          t={t}
        />
        {totalResultCount > 0 && (
          <>
            <div className="mt-4 flex items-center gap-16">
              {!!products?.length && (
                <div className="flex w-1/2 gap-8 flex-col lg:flex-row items-center">
                  {products.slice(0, 2).map(product => (
                    <ProductCard
                      product={product}
                      t={t}
                      key={`searchProduct${product.id}`}
                      isInPageSearch={isInPageSearch}
                    />
                  ))}
                    <a
                      href={`/search?q=${query}`}
                      className="button button--primary mb-6 mt-4 text-center lg:hidden"
                    >
                      {`${t("action.see_al_results")} (${totalResultCount})`}
                    </a>
                </div>
              )}
              {!!articles?.length && (
                <div className="flex w-1/2 flex-col gap-4">
                  {articles.slice(0, 3).map(article => (
                    <ArticleCard
                      article={article}
                      t={t}
                      key={`searchArticle${article.id}`}
                      isInPageSearch={isInPageSearch}
                    />
                  ))}
                </div>
              )}
            </div>

            <a
              href={`/search?q=${query}`}
              className="button button--primary mb-6 mt-4 text-center hidden lg:inline-block"
            >
              {`${t("action.see_al_results")} (${totalResultCount})`}
            </a>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="search-grid container pt-4 text-deep-green md:gap-x-24">
      <SearchBar
        isSearching={isSearching}
        inputValue={inputValue}
        handleInputChange={handleInputChange}
        totalResultCount={totalResultCount}
        handleSuggestionClick={handleSuggestionClick}
        suggestions={suggestions}
        query={query}
        t={t}
      />
      {!!products?.length && (
        <div className="search-products mb-4 mt-4 grid auto-rows-min grid-cols-2 items-end gap-8">
          <div className="col-span-2 flex justify-center md:justify-between">
            <h2 className="text-center text-[32px]">{t("common.products")}</h2>
            <a
              href="/collections/all"
              className="button button--tertiary col-span-2 hidden text-center md:flex"
            >
              {t("action.shop_all")}
            </a>
          </div>
          <PaginatedList
            itemsPerRow={isMobile ? 1 : 4}
            rows={isMobile ? 999 : 1}
            className="col-start-1 col-end-3"
          >
            {products.slice(0, maxProductsToShow).map(product => (
              <ProductCard
                product={product}
                t={t}
                key={`searchProduct${product.id}`}
              />
            ))}
          </PaginatedList>
          {maxProductsToShow < products.length ? (
            <button
              className="button button--tertiary col-span-2 text-center"
              onClick={() => setMaxProductsToShow(999)}
            >
              {t("search.show_all")}
            </button>
          ) : (
            <a
              href="/collections/all"
              className="button button--tertiary col-span-2 text-center md:hidden"
            >
              {t("action.shop_all")}
            </a>
          )}
          {articles?.length && <hr className="col-span-2" />}
        </div>
      )}

      {!!articles?.length && (
        <div className="search-articles mt-4 flex flex-col gap-8">
          <div className="col-span-2 mb-4 flex justify-center md:justify-between">
            <h2 className="text-center text-[32px]">{t("common.ph_blog")}</h2>
            <a
              href="/blog"
              className="button button--tertiary col-span-2 hidden text-center md:flex"
            >
              {t("action.read_blog")}
            </a>
          </div>
          <PaginatedList
            itemsPerRow={isMobile ? 1 : 4}
            rows={isMobile ? 3 : 2}
            className="col-start-1 col-end-3"
          >
            {articles.map(article => (
              <ArticleCard
                article={article}
                t={t}
                key={`searchArticle${article.id}`}
              />
            ))}
          </PaginatedList>
          <a
            href="/blog"
            className="button button--tertiary text-center md:hidden"
          >
            {t("action.read_blog")}
          </a>
        </div>
      )}
    </div>
  );
};

const SearchBar = ({
  isSearching,
  inputValue,
  handleInputChange,
  totalResultCount,
  handleSuggestionClick,
  suggestions,
  isInPageSearch,
  query,
  t,
}) => (
  <>
    <div className="search-bar mb-6">
      <label
        className={`search-bar search-bar-icon ${isSearching ? "search-bar-icon--loading" : ""}`}
      >
        <input
          type="text"
          placeholder={t("action.search_brand")}
          value={inputValue}
          autoFocus
          className={`w-full rounded-full bg-white leading-[52px] outline-dark-green ${isInPageSearch ? "pl-[72px] pr-6" : "px-6"}`}
          onChange={event => handleInputChange(event.target.value)}
        />
        {isInPageSearch && (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute right-[30px] top-[-2px] cursor-pointer"
            onClick={() =>
              window.dispatchEvent(new CustomEvent("search:dropdown:toggle"))
            }
          >
            <path
              d="M1.72392 23.9413L12.3906 13.2747L23.0572 23.9413L23.9999 22.9987L13.3332 12.332L23.9999 1.66532L23.0572 0.722656L12.3906 11.3893L1.72392 0.722656L0.78125 1.66532L11.4479 12.332L0.78125 22.9987L1.72392 23.9413Z"
              fill="#0B2E24"
            />
          </svg>
        )}
      </label>
    </div>
    {totalResultCount === 0 && <NoResults query={query} t={t} />}
    {totalResultCount > 0 && (
      <div className="search-total-results mb-2 text-center text-[14px]">{`We found ${totalResultCount} results`}</div>
    )}
    {!totalResultCount > 0 && (
      <div className="pb-8 leading-loose">
        <div className="text-[14px] font-semibold">Suggested</div>
        {suggestions?.map(suggestion => (
          <div
            className="suggestion-arrow w-max cursor-pointer text-[24px]"
            key={`${suggestion}-item`}
            onClick={() => {
              handleSuggestionClick(suggestion);
            }}
          >
            {suggestion}
          </div>
        ))}
      </div>
    )}
    {totalResultCount === 0 && (
      <a
        href="/collections/all"
        className="button button--primary mt-4 text-center"
      >
        {t("action.shop_brand")}
      </a>
    )}
  </>
);

const NoResults = ({query}) => (
  <div className="search-no-results mb-8">
    <div className="text-[32px]">{`Oops, there are no results for ${query}`}</div>
  </div>
);

const ProductCard = ({product, t, isInPageSearch}) => (
  <div
    className={`product-item flex flex-col gap-2 ${isInPageSearch ? "w-7/12 lg:w-1/2" : ""}`}
  >
    <a
      href={product.onlineStoreUrl}
      aria-label={product.title}
      className="relative block aspect-square max-h-[300px] w-full overflow-hidden"
    >
      <img
        sizes=""
        src={product.featuredImage?.url}
        alt={product.featuredImage?.altText}
        loading="lazy"
        className="image object-contain hover:opacity-70"
      />
    </a>
    <div className="flex justify-between">
      <div className="font-semibold">{product.title}</div>
      <div>{getLowestPriceFromVariants(product.variants)}</div>
    </div>
    <a
      href={product.onlineStoreUrl}
      className="button button--secondary text-center"
    >
      {t("action.shop")}
    </a>
  </div>
);

const ArticleCard = ({article, t, isInPageSearch}) => (
  <div className={`flex flex-col ${isInPageSearch ? "gap-2" : "gap-4"}`}>
    <div
      className={`font-semibold max-md:-mb-2 ${isInPageSearch ? "text-[20px]" : "text-[20px] md:text-[24px]"}`}
    >
      {article.title}
    </div>
    <div className={`${isInPageSearch ? "text-[14px]" : "text-[16px]"}`}>
      {article.content}
    </div>
    <a
      href={article.onlineStoreUrl}
      className="button button--secondary text-center md:hidden"
    >
      {t("action.read_more")}
    </a>
    <a
      href={article.onlineStoreUrl}
      className={`hidden underline md:block ${isInPageSearch ? "text-[14px]" : ""}`}
    >
      {t("action.read_more")}
    </a>
  </div>
);

function getLowestPriceFromVariants(variantArray = []) {
  const lowestPrice = variantArray.reduce((currentLowestPrice, variant) => {
    let highestDiscount = 0;
    if (variant.sellingPlans) {
      highestDiscount = variant.sellingPlans.reduce(
        (accumulator, {adjustmentPercentage}) =>
          Math.max(accumulator, adjustmentPercentage),
        0,
      );
    }
    const variantLowestPrice =
      variant.price.amount * ((100 - highestDiscount) / 100);
    return Math.min(currentLowestPrice, variantLowestPrice);
  }, 999999);
  return formatMoney(lowestPrice * 100);
}

const getSearchResults = (
  question,
  setProducts,
  setArticles,
  setIsSearching,
) => {
  if (!question) {
    setArticles(null);
    setProducts(null);
    setIsSearching(false);
    return;
  }
  const query = `{
    search(query: "${question}", first: 100) {
      edges {
        node {
          ... on Product {
            id
            title
            tags
            availableForSale
            onlineStoreUrl
            featuredImage {
              url
              altText
              width
              height
            }
            variants(first: 100) {
              edges {
                node {
                  id
                  sku
                  price {
                    amount
                    currencyCode
                  }
                  sellingPlanAllocations(first: 100) {
                    edges {
                      node {
                        sellingPlan {
                          id
                          name
                          priceAdjustments {
                            orderCount
                            adjustmentValue {
                              ... on SellingPlanPercentagePriceAdjustment {
                                adjustmentPercentage
                              }
                              
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on Page {
            id
            title
          }
          ... on Article {
            id
            title
            onlineStoreUrl
            content(truncateAt: 200)
          }
        }
      }
    }
  }`;

  const endpoint = `https://${window.Shopify.shop}/api/2024-07/graphql.json`;
  const headers = {
    "Content-Type": "application/graphql",
    "X-Shopify-Storefront-Access-Token": window.Theme.api_key,
  };

  fetch(endpoint, {
    method: "POST",
    headers,
    body: query,
  })
    .then(response => response.json())
    .then(data => {
      const results = data?.data?.search?.edges || [];
      const products = [];
      const articles = [];
      results.forEach(({node}) => {
        if (node.id.startsWith("gid://shopify/Product/")) {
          if (products.length < 100) {
            products.push({
              id: node.id,
              title: node.title,
              featuredImage: node.featuredImage,
              sku: node.variants.edges[0].node.sku,
              isMainProduct: node.tags.includes("is_main_product"),
              onlineStoreUrl: node.onlineStoreUrl,
              variants: node.variants.edges.map(({node}) => ({
                id: node.id,
                price: {
                  amount: node.price.amount,
                  currencyCode: node.price.currencyCode,
                },
                sellingPlans: node.sellingPlanAllocations?.edges.map(
                  ({node}) => ({
                    id: node.sellingPlan.id,
                    name: node.sellingPlan.name,
                    adjustmentPercentage:
                      node.sellingPlan.priceAdjustments[0]?.adjustmentValue
                        ?.adjustmentPercentage,
                  }),
                ),
              })),
            });
          }
        } else if (node.id.startsWith("gid://shopify/Article/")) {
          if (articles.length < 100) {
            articles.push({...node});
          }
        }
      });
      setArticles(articles);
      setProducts(products.filter(el => !!el.onlineStoreUrl).filter(product => product.isMainProduct));
      setIsSearching(false);
      return;
    })
    .catch(error => {
      setIsSearching(false);
      console.error(error);
    });
};

function updateQueryParameter(key, value) {
  const url = new URL(window.location);
  if (value) {
    url.searchParams.set(key, value);
  } else {
    url.searchParams.delete(key);
  }

  // Update the URL in the browser without reloading
  window.history.replaceState({}, "", url);
}

SearchBar.propTypes = {
  suggestions: PropTypes.array,
  isSearching: PropTypes.bool,
  isInPageSearch: PropTypes.bool,
  inputValue: PropTypes.string,
  handleInputChange: PropTypes.func,
  totalResultCount: PropTypes.number,
  handleSuggestionClick: PropTypes.func,
  query: PropTypes.string,
  t: PropTypes.func,
};

NoResults.propTypes = {
  query: PropTypes.string,
  t: PropTypes.func,
};

ProductCard.propTypes = {
  product: PropTypes.object,
  t: PropTypes.func,
  isInPageSearch: PropTypes.bool,
};

ArticleCard.propTypes = {
  article: PropTypes.object,
  t: PropTypes.func,
  isInPageSearch: PropTypes.bool,
};

Search.propTypes = {
  suggestions: PropTypes.array,
  isInPageSearch: PropTypes.bool,
};

export default Search;
