import {PropTypes} from "prop-types";
import {useState} from "react";
import {CSSTransition, SwitchTransition} from "react-transition-group";

const PaginatedList = ({
  children,
  itemsPerRow = 3,
  rows = 2,
  className,
  itemGap = 36,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the total items per page and total pages
  const itemsPerPage = itemsPerRow * rows;
  const totalPages = Math.ceil(children.length / itemsPerPage);

  // Determine the items to display on the current page
  const currentItems = children.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  // Generate page numbers for pagination
  const getPageNumbers = () => {
    const pageNumbers = [];

    if (totalPages <= 5) {
      // Show all page numbers if total pages are 5 or less
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Always include the first and last page
      pageNumbers.push(1);

      // Calculate the range to display between
      let start = Math.max(2, currentPage - 1);
      let end = Math.min(totalPages - 1, currentPage + 1);

      if (currentPage === 1) {
        end = Math.min(4, totalPages - 1);
      } else if (currentPage === totalPages) {
        start = Math.max(2, totalPages - 3);
      }

      // Add "..." if there is a gap between 1 and start
      if (start > 2) {
        pageNumbers.push("...");
      }

      // Add the middle page numbers
      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      // Add "..." if there is a gap between end and last page
      if (end < totalPages - 1) {
        pageNumbers.push("...");
      }

      // Always include the last page
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  // Handle page change
  const handlePageChange = page => {
    if (page !== "..." && page !== currentPage) {
      setCurrentPage(page);
    }
  };
  const pageNumbers = getPageNumbers();
  return (
    <div className={className}>
      <div
        className="paginated-items relative w-full"
        style={{overflow: "hidden"}}
      >
        <SwitchTransition mode="out-in">
          <CSSTransition key={currentPage} timeout={300} classNames="fade">
            <div
              className="items-grid grid"
              style={{
                gridTemplateColumns: `repeat(${itemsPerRow}, 1fr)`,
                gap: itemGap + "px",
              }}
            >
              {currentItems.map((item, index) => (
                <div key={index} className="item">
                  {item}
                </div>
              ))}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
      {pageNumbers?.length > 1 && (
        <div className="pagination-controls mt-4 flex justify-center gap-2">
          {pageNumbers.map((page, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(page)}
              disabled={page === "..." || page === currentPage}
              className={`mx-1 my-2 cursor-pointer border-deep-green px-1 py-1 text-[14px] ${page === currentPage ? "border-b-2 font-semibold" : ""}`}
            >
              {page}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

PaginatedList.propTypes = {
  children: PropTypes.array,
  itemsPerRow: PropTypes.number,
  rows: PropTypes.number,
  className: PropTypes.string,
  itemGap: PropTypes.number,
};

export default PaginatedList;
